<template>
    <div class="paper-detail" v-if="moduleTabs.length > 0">
        <div class="head-tabs">
            <el-tabs v-model="tabIndex" type="card" @tab-click="tabChange">
                <el-tab-pane v-for="item in moduleTabs" :key="item.op_id" :label="item.module_name" v-if="item.exam_module_id"> </el-tab-pane>
            </el-tabs>
        </div>
        <div class="content" v-if="getPaperModuleDetailParams.type && getPaperModuleDetailParams.opId">
            <operation-mould v-bind="getPaperModuleDetailParams" ref="moduleDetail"></operation-mould>
        </div>
    </div>
</template>

<script>
import OperationMould from "components/evaluation/OperationMould";

import { getIdentifyActualDetailModuleData } from "@/utils/apis";
export default {
    name: "ActualPaperDetail",
    data() {
        return {
            getModuleParams: {
                type: "",
                operation_paper_id: "",
            },
            getPaperModuleDetailParams: {
                type: "",
                opId: "",
            },
            tabIndex: 0,
            moduleTabs: [],
        };
    },
    computed: {},
    components: {
        OperationMould,
    },
    methods: {
        init() {
            this.getModuleData();
        },
        async getModuleData() {
            let { getPaperModuleDetailParams: params, moduleTabs, tabIndex } = this;
            let res = await getIdentifyActualDetailModuleData(this.getModuleParams);
            if (res.code == 200) {
                this.moduleTabs = res.data.filter(item => item.exam_module_id);
                this.$nextTick(() => this.setModuleParams(0));
            }
        },
        tabChange() {
            this.setModuleParams(this.tabIndex);
            this.$nextTick(() => this.$refs.moduleDetail.getExamStuExamPage());
        },
        setModuleParams(index) {
            let { getPaperModuleDetailParams: params, moduleTabs } = this;
            let type;
            switch (moduleTabs[index].exam_module_id) {
                // 商品信息采集与处理
                case 8:
                case 9:
                case 10:
                case 58:
                    type = "goods_info_collect";
                    break;
                // 网店管理
                case 15:
                case 16:
                case 18:
                case 67:
                case 91:
                    type = "shop_management";
                    break;
                // 客服
                case 21:
                case 69:
                case 70:
                case 71:
                case 94:
                    type = "store_customer_service";
                    break;
                // 数据收集与清洗
                case 22:
                case 23:
                case 72:
                case 73:
                    type = "data_capture_clean";
                    break;
                // 网店装修
                case 11:
                case 12:
                case 13:
                case 14:
                    type = "shop_decoration";
                    break;
                // 网络推广
                case 19:
                case 20:
                case 64:
                    type = "network_promotion";
                    break;
                //网络客户
                case 42:
                case 43:
                    type = "store_customer_service ";
                    break;
                // 三级 网络营销
                case 33:
                case 34:
                case 35:
                case 36:
                case 37:
                case 38:
                case 88:
                    type = "network_marketing";
                    break;
                // 三级 网络营销
                case 44:
                case 45:
                case 46:
                case 50:
                case 97:
                    type = "data_analysis_application";
                    break;
                //  网上交易管理
                case 39:
                case 40:
                case 41:
                    type = "online_transaction_management";
                    break;
                // 三级 网店视觉设计
                case 30:
                case 31:
                case 32:
                case 59:
                case 85:
                    type = "store_visual_design";
                    break;
                case 82:
                    type = "product_service_management";
                    break;
            }
            params.type = type;
            params.opId = moduleTabs[index].op_id;
        },
    },
    mounted() {
        const { type, id } = this.$route.query;
        this.getModuleParams.type = type;
        this.getModuleParams.operation_paper_id = id;
        this.init();
    },
};
</script>

<style scoped lang="scss">
.paper-detail {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    height: 100%;
    overflow: hidden;
    .content {
        flex: 1;
        overflow: hidden;
    }
}
.paper-detail {
    ::v-deep .el-tabs .is-active {
        transition: all 0.3s;
        background-color: #e7f6ef;
    }
}
</style>
